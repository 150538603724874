(function (module, isBrowser) {
    var computeOrder = order => {
        if (!order) {
            return;
        }

        order.discount = order.discount || 0;
        order.taxRate = order.taxRate || 0;

        if (order.orderItems) {
            var itemTotals = (order.orderItems || []).map(computeItem);
            order.subtotal = itemTotals.reduce((val, rowTotal) => val + rowTotal.total, 0);
        }

        if (order.subtotal) {
            order.beforeTax = order.subtotal - parseFloat('' + order.discount);
            order.tax = Math.max(0, order.beforeTax * (parseFloat('' + order.taxRate) / 100));
            order.total = order.beforeTax + order.tax;
        }

        if (order.subtotal === 0) {
            order.total = order.tax = order.beforeTax = 0;
        }

        if (order.fee !== undefined) {
            order.revenue = order.total - parseFloat('' + order.fee);
        }

        return order;
    };

    const computeItem = (content, quantity) => {
        var orderItem, meta;

        //Check if content is an orderItem

        if (content.content) {
            orderItem = content;
            meta = orderItem.contentMetadata;
            quantity = content.quantity || 0;
            content = content.content;
        }

        if (!meta) {
            meta = content.metadata;
        }

        if (!meta) {
            console.log('Invalid meta');
            meta = {price: 0};
        }

        var price = {
            total: parseInt(quantity) * parseFloat('' + meta.price),
            description: quantity + ' license' + (quantity !== 1 ? 's' : '') + ' @ $' + meta.price
        };

        if (meta.tieredPricing) {
            price = meta.pricingTiers.reduce((val, tier, idx) => {
                var count = Math.min((tier.high || 999999999999) - tier.low, quantity);
                quantity -= count;

                if (count > 0) {
                    val.total += count * parseFloat('' + tier.price);
                    val.description += (idx > 0 ? ', ' : '') + count + ' license' + (count !== 1 ? 's' : '') + ' at $' + tier.price
                }

                return val;
            }, {total: 0, description: ''});
        }

        if (orderItem) {
            if (orderItem.priceOverride) {
                price.total = parseFloat('' + orderItem.priceOverride);
            }

            orderItem.price = price;
        }

        return price;
    };

    var retval = {
        computeOrder,
        computeItem
    };

    if (isBrowser) {
        module.orderTools = retval;
    } else {
        module.exports = retval;
    }
}((typeof module === 'undefined' ? (this.atlas = (this.atlas || {})) : module), typeof module === 'undefined'));
