(function (module, isBrowser) {
    var mime;

    if (!isBrowser) {
        mime = require('mime');
    }

    var FILE_TYPES = {
        'presentation': ['ppt', 'pps', 'pptx', 'odp', 'odg', 'otp'],
        'document': ['doc', 'docx', 'odt', 'ott'],
        'spreadsheet': ['xls', 'xlsx', 'googleform', 'ods', 'ots', 'xlw', 'xlt', 'csv'],
        'image': ['png', 'jpg', 'jpeg', 'svg', 'gif', 'tiff'],
        'audio': ['mp3', 'wav', 'aac', 'ogg', 'm4a'],
        'video': ['mov', 'mp4', 'm4v', '3gp', 'h264', 'mkv', 'avi', 'mpg', 'mpeg', 'ogv'],
        'pdf': ['pdf']
    };

    var importFormats = [
        "application/x-vnd.oasis.opendocument.presentation",
        "application/vnd.google-apps.presentation",
        "text/tab-separated-values",
        "application/vnd.google-apps.spreadsheet",
        "application/vnd.google-apps.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.sun.xml.writer",
        "text/rtf",
        "text/plain",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/x-vnd.oasis.opendocument.spreadsheet",
        "application/x-vnd.oasis.opendocument.text",
        "application/msword",
        "application/pdf",
        "application/json",
        "application/vnd.google-apps.script",
        "application/x-msmetafile",
        "application/vnd.google-apps.drawing",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
        "application/vnd.ms-powerpoint",
        "application/rtf",
        "application/vnd.openxmlformats-officedocument.presentationml.template",
        "text/html",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.google-apps.script+json",
        "application/vnd.google-apps.script",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "text/csv",
        "application/vnd.oasis.opendocument.presentation",
        "text/richtext"
    ];

    function checkAttachment(attachment) {
        var extension;
        if (!attachment.metadata) {
            attachment.metadata = {
                mimeType: attachment.mimeType,
                filesize: attachment.filesize,
                filename: attachment.filename
            };
        }

        var meta = attachment.metadata;

        if (!attachment.url && (attachment.data || {}).url) {
            attachment.url = attachment.data.url;
        }

        if (!meta.filename && attachment.url) {
            meta.filename = attachment.url.split('/').pop() || '';
        }

        if (attachment.url && attachment.url.indexOf('https://drive.google.com/file/d/') === 0 && !attachment.serviceKey) {
            attachment.serviceKey = 'drive';
            attachment.isAtlasGoogle = true;
            attachment.serviceId = attachment.url.split('/')[5];
        }

        if (!meta.extension && meta.filename && meta.filename.indexOf('.') !== -1) {
            meta.extension = meta.filename.split('.').pop();
        }

        if (!meta.extension && meta.mimeType && !!mime) {
            meta.extension = mime.extension(meta.mimeType);
        }

        if (meta.extension) {
            meta.extension = meta.extension.toLowerCase();
            extension = meta.extension;

            if (!meta.mimeType && !!mime) {
                meta.mimeType = mime.lookup(extension);
            }

            if (!attachment.contentType || attachment.contentType === 'file') {
                if (['mp3', 'm4a', 'wav', 'aif', 'audio'].indexOf(extension) !== -1) {
                    attachment.contentType = 'audio';
                } else if (['mp4', 'mov'].indexOf(extension) !== -1) {
                    attachment.contentType = 'video';
                } else if ((attachment.mimeType || '').indexOf('image/') !== -1 || ['png', 'jpg', 'gif', 'svg'].indexOf(extension) !== -1) {
                    attachment.contentType = 'image';
                } else if (attachment.noframe) {
                    attachment.contentType = 'link';
                } else if ((attachment.url || '').indexOf('https://www.youtube.com/embed/') === 0) {
                    attachment.contentType = 'youtube';
                } else if (attachment.url) {
                    attachment.contentType = 'iframe';
                } else {
                    attachment.contentType = 'note';
                }
            }

            if (!meta.fileType) {
                Object.keys(FILE_TYPES).forEach(function (type) {
                    if (!meta.fileType && FILE_TYPES[type].indexOf(extension) !== -1) {
                        meta.fileType = type;
                    }
                });
            }
        }

        if (!meta.icon) {
            meta.icon = (meta.fileType || 'unknown');
        }

        if (meta.mimeType && !attachment.canConvert) {
            attachment.canConvert = importFormats.indexOf(meta.mimeType) !== -1 || meta.mimeType.indexOf('image') === 0 || meta.mimeType === 'application/pdf';
        }

        if (!attachment.contentType) {
            if (attachment.annotations) {
                attachment.contentType = 'draw';
            } else if (attachment.body) {
                attachment.contentType = 'note';
            } else if (attachment.url) {
                attachment.contentType = 'link';
            }
        }

        attachment.meta = {
            icon: meta.icon
        };

        return attachment;
    }

    function processGoogleDriveFile(props) {
        if (props.sharingResult) {
            return {
                serviceId: props.id || props.serviceId,
                isAtlasGoogle: true,
                shared: true
            };
        }

        var attach = {metadata: {}, data: {}, meta: {}};

        if (props.data && !props.id) {
            props = props.data;
        }

        attach.serviceId = props.id;
        attach.serviceKey = 'drive';
        attach.lastModified = props.modifiedTime;
        attach.dateUpdated = props.modifiedTime;
        attach.dateCreated = props.createdTime;
        attach.title = props.name;

        attach.isAtlasGoogle = (props.owners || []).filter(o => o.emailAddress === 'atlas@atlasfiles.net').length > 0;

        if (props.permissions && props.permissions.length) {
            props.shared = props.permissions.filter(p => p.type === 'anyone').length > 0;
        }

        if (props.mimeType === 'application/vnd.google-apps.folder') {
            attach.type = 'folder';
            attach.metadata.mimeType = props.mimeType;
            attach.meta = {
                icon: 'folder'
            };
        } else {
            attach.type = 'attachment';
            attach.metadata.mimeType = props.mimeType;
            attach.metadata.extension = (props.fileExtension || '').toLowerCase() || (props.originalFilename || props.name || '').split('.').pop();

            attach.metadata.extension = attach.metadata.extension.toLowerCase();

            attach.metadata.filename = props.originalFilename || attach.title || attach.name;

            if (attach.metadata.extension && attach.metadata.filename.substring(attach.metadata.filename.length - attach.metadata.extension.length) !== attach.metadata.extension) {
                attach.metadata.filename += '.' + attach.metadata.extension;
            }

            attach.metadata.thumbnail = props.thumbnailLink;
            if (attach.metadata.mimeType && !attach.canConvert) {
                attach.canConvert = importFormats.indexOf(attach.metadata.mimeType) !== -1 || attach.metadata.mimeType.indexOf('image') === 0 || attach.metadata.mimeType === 'application/pdf';
            }
            attach.data.url = 'https://drive.google.com/file/d/' + attach.serviceId + '/preview';
            attach.contentType = 'iframe';

            if (props.shared) {
                attach.shared = true;
                attach.metadata.downloadLink = props.webContentLink;
            }

            checkAttachment(attach);
        }

        return attach;
    }

    var retval = {
        fileFields: 'mimeType,id,name,webContentLink,parents,createdTime,permissions(emailAddress,type),fileExtension,shared,owners(emailAddress),modifiedTime,originalFilename',
        processGoogleFile: processGoogleDriveFile,
        checkAttachment: checkAttachment
    };

    if (isBrowser) {
        module.attachments = retval;
    } else {
        module.exports = retval;
    }
}((typeof module === 'undefined' ? (this.atlas = (this.atlas || {})) : module), typeof module === 'undefined'));
